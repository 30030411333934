import { SystemEvent } from '@mbrtargeting/metatag-shared-types';
import { FetchPriority, loadScript } from '@mbrtargeting/metatag-utils';
import { simpleParamsSerializer } from '../../utils/url-helper.js';
import { inject, injectionTarget } from '../decorators/inject.js';
import { onSystemEvent } from '../decorators/on-system-event.js';
import { ConfigResolver } from '../essentials/config-resolver.js';
import { logCrit, logNotice, logWarn } from '../essentials/logger.js';
import { ConsentCache } from './consent-cache.js';

declare const window: Window & yieldlove.Window;

@injectionTarget()
export class YieldloveLoader {

    @inject() #configResolver!: ConfigResolver;
    @inject() #consentCache!: ConsentCache;

    @onSystemEvent(SystemEvent.SDG_CONFIG_FILE_AVAILABLE)
    async loadYieldloveScript() {
        const { active, yieldloveDomainName, yieldloveVersion, loadYieldloveScript = true, iabVendorId = 251, delayScriptCallUntilConsent = false } = this.#configResolver.getModuleSettings('PREBID') || {};

        if (!active) {
            logWarn('YIELDLOVE: not loading yieldlove script as active is false');
            return;
        }
        if (!yieldloveDomainName) {
            logWarn('YIELDLOVE: not loading yieldlove script as yieldloveDomainName is not set');
            return;
        }

        // prevent yieldlove to instantiate bidder, we're doing it manually
        window.yieldlove_prevent_autoload = true;
        // prevent yieldlove autostart an auction for all found gpt adunits
        window.yieldlove_prevent_autostart = true;
        // disable adcalls by yieldlove, we're doing gpt requests
        window.yieldlove_stop_adserver_requests = true;

        // do not load yieldlove script if publisher wants to do it manually
        if (!loadYieldloveScript) {
            logWarn('YIELDLOVE: not loading yieldlove script as loadYieldloveScript is false');
            return;
        }

        // if script parameters only contain site_id, just return it to use short syntax
        const encodeParams = (p: yieldlove.ScriptParams): string => Object.keys(p).length === 1 && p.site_id ? p.site_id : simpleParamsSerializer(p);

        const injectScript = () => {
            const query = encodeParams({
                site_id: yieldloveDomainName,
                ...yieldloveVersion && { version: yieldloveVersion },
            });
            loadScript({
                src: `https://cdn-a.yieldlove.com/v2/yieldlove-stroeer.js?${query}`,
                node: document.head,
                fetchpriority: FetchPriority.HIGH,
                onload: () => logNotice('YIELDLOVE: script loaded'),
                onerror: (event) => logCrit('YIELDLOVE: script could not be loaded', [event]),
            });
        }

        if (!delayScriptCallUntilConsent) {
            return injectScript();
        }

        const allowed: boolean = await this.#consentCache.whenConsentConditions([
            { consentedVendors: [iabVendorId] },
            { legitimateInterestVendors: [iabVendorId] },
            { gdprApplies: false },
        ]);

        if (allowed) {
            return injectScript();
        }
    }
}
