import { loadScript } from '@mbrtargeting/metatag-utils';
import { showNotification } from '../../utils/notification-helper.js';
import { createLongTouchListner } from '../../utils/touch-helper.js';
import { inject, injectionTarget } from '../decorators/inject.js';
import { ConfigResolver } from '../essentials/config-resolver.js';
import { logCrit, logDebug, logError } from '../essentials/logger.js';

declare var window: Window & {
    showSdgDebug: () => void;
};

@injectionTarget()
export class InfotoolLoader {

    @inject() #configResolver!: ConfigResolver;

    constructor() {
        // register the window.showSdgDebug() function
        window.showSdgDebug = () => this.loadInfotool();

        // create a touch listener to load the infotool with a 3-seconds-three-finger-touch on mobile devices
        createLongTouchListner({
            touchCount: 3,
            touchDuration: 3000,
            onTouch: () => showNotification({ text: 'wait...', duration: 3000 }),
            onCancel: () => showNotification({ text: 'canceled', duration: 1000 }),
            onLongTouch: () => {
                showNotification({ text: 'loading infotool', duration: 1000 });
                this.loadInfotool();
            }
        });
    }

    private loadInfotool(): void {
        const { infoToolUrl = '' } = this.#configResolver.getCommonSettings();

        if (!infoToolUrl?.length) {
            logCrit('SYSTEM: need infoToolUrl to load info tool!');
            return;
        }

        loadScript({
            src: infoToolUrl,
            node: document.head,
            onload: () => logDebug('SYSTEM: infotool successfully loaded: %s', [infoToolUrl]),
            onerror: () => logError('SYSTEM: infotool could not be loaded: %s', [infoToolUrl]),
        });
    }
}
