import { FetchPriority, loadScript } from '@mbrtargeting/metatag-utils';
import { ConfigResolver } from '../loader/essentials/config-resolver.js';
import { Registry } from '../loader/registry.js';

/** removes all trailing slashes from a string if present */
const stripTrailingSlashes = (s: string): string => s.replace(/\/+$/, '');

/** remove the filename from a path or url, e.g /folder1/folder2/filename.js => /folder1/folder2 */
const stripFilename = (s: string): string => s.substring(0, s.lastIndexOf('/'));

/** try to determine the base url of the current script if browser supports this */
const getCurrentScriptBaseUrl = (): string => {
    try {
        throw new Error();
    } catch (error: unknown) {
        const matches = (error as Error).stack?.match(/(https?|file):\/\/[^\s]+\.js/);
        if (matches && matches.length > 0) {
            return stripFilename(matches[0]);
        }
    }
    return '';
};

export const loadDependency = (fileName: string, onload: () => void, onerror: OnErrorEventHandler): void => {
    // get v2BaseUrl from config if present, otherwise get it from current script
    const { v2BaseUrl = getCurrentScriptBaseUrl() } = Registry.lookup<ConfigResolver>('ConfigResolver').getCommonSettings();

    loadScript({
        src: `${stripTrailingSlashes(v2BaseUrl)}/${fileName}`,
        node: document.head,
        fetchpriority: FetchPriority.HIGH,
        onload,
        onerror,
    });
};
