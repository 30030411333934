import { ReplaySubject, cssText } from '@mbrtargeting/metatag-utils';
import { simpleStorageSwitch } from '../../utils/devops-options-helper.js';

export enum LogLevel {
    DEBUG = 10,
    INFO = 20,
    NOTICE = 30,
    DEPRECATED = 35,
    WARNING = 40,
    ERROR = 50,
    CRITICAL = 60,
    ALERT = 70,
    EMERGENCY = 80
}

interface LogEntry {
    date: Date;
    logLevel: LogLevel;
    message: string;
    messageObjects?: any[];
}

class ColorfulConsole {

    protected prefix = 'SDG';

    #styles: {
        prefixStyle: string;
        levelStyles: Record<LogLevel, string>;
        messageStyle: string;
    };

    constructor() {
        // some shared styling
        const baseCss: Partial<CSSStyleDeclaration> = { display: 'inline-block', marginRight: '2px', padding: '2px 4px', borderRadius: '5px' };

        // prepare all styles to make logging faster
        this.#styles = {
            prefixStyle: cssText({ ...baseCss, color: '#fff', backgroundColor: '#005276' }),
            levelStyles: {
                [LogLevel.DEBUG]: cssText({ ...baseCss, color: '#fff', backgroundColor: '#2e8b57' }),
                [LogLevel.INFO]: cssText({ ...baseCss, color: '#fff', backgroundColor: '#6b5b95' }),
                [LogLevel.NOTICE]: cssText({ ...baseCss, backgroundColor: '#b2ad7f' }),
                [LogLevel.DEPRECATED]: cssText({ ...baseCss, backgroundColor: '#f84df8' }),
                [LogLevel.WARNING]: cssText({ ...baseCss, backgroundColor: '#ffbcdf' }),
                [LogLevel.ERROR]: cssText({ ...baseCss, backgroundColor: '#ff859d' }),
                [LogLevel.CRITICAL]: cssText({ ...baseCss, backgroundColor: '#fd4d59' }),
                [LogLevel.ALERT]: cssText({ ...baseCss, backgroundColor: '#c30053' }),
                [LogLevel.EMERGENCY]: cssText({ ...baseCss, color: '#fff', backgroundColor: '#cf000f' }),
            },
            messageStyle: '',
        };
    }

    public sendLogToConsole({ logLevel, message, messageObjects = [] }: LogEntry): void {
        const { prefixStyle, levelStyles, messageStyle } = this.#styles;
        window.console?.log?.apply(this, [`%c${this.prefix}%c${LogLevel[logLevel]}%c ${message}`, prefixStyle, levelStyles[logLevel], messageStyle, ...messageObjects]);
    }
}

// creating a logSink with css styling - assuming all modern browsers support this as we have dropped support for IE
const logSink = new ColorfulConsole();

// reading the wanted console log level from storage entry
const consoleLogLevel = parseInt(simpleStorageSwitch('sdgDumpLogsToConsole', /^([0-9]+)$/) || '') || LogLevel.ERROR;

// the subject where all log entries are stored internally
export const logEntries$ = new ReplaySubject<LogEntry>(1e6);

// send the logs to the browser console
logEntries$
    .filter(entry => entry.logLevel >= consoleLogLevel)
    .subscribe(entry => logSink.sendLogToConsole(entry));

// logger factory function
const logFn = (logLevel: LogLevel) => (message: string, messageObjects?: any[]) => logEntries$.next({ date: new Date(), logLevel, message, messageObjects });

// exporting log helper functions
export const logDebug = logFn(LogLevel.DEBUG);
export const logInfo = logFn(LogLevel.INFO);
export const logNotice = logFn(LogLevel.NOTICE);
export const logDeprecated = logFn(LogLevel.DEPRECATED);
export const logWarn = logFn(LogLevel.WARNING);
export const logError = logFn(LogLevel.ERROR);
export const logCrit = logFn(LogLevel.CRITICAL);
export const logAlert = logFn(LogLevel.ALERT);
export const logFatal = logFn(LogLevel.EMERGENCY);
