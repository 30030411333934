import { SystemEvent } from '@mbrtargeting/metatag-shared-types/metatag-core';
import { isString } from '@mbrtargeting/metatag-utils';
import { loadDependency } from '../../utils/load-dependency.js';
import { inject, injectionTarget } from '../decorators/inject.js';
import { onSystemEvent } from '../decorators/on-system-event.js';
import { ConfigResolver } from '../essentials/config-resolver.js';
import { logCrit, logDebug } from '../essentials/logger.js';

@injectionTarget()
export class CoreFileLoader {

    @inject() #configResolver!: ConfigResolver;

    /**
     * loads the core part of metaTag
     */
    @onSystemEvent(SystemEvent.SDG_CONFIG_FILE_AVAILABLE)
    public loadCoreFile(): void {
        // get v2CoreFile from config
        const { v2CoreFile } = this.#configResolver.getCommonSettings();

        if (!isString(v2CoreFile)) {
            logCrit('SYSTEM: need v2CoreFile to load core file!');
            return;
        }
        loadDependency(
            v2CoreFile,
            () => logDebug('SYSTEM: core successfully loaded: %s', [v2CoreFile]),
            (event) => logCrit('SYSTEM: core could not be loaded: %s <-- MetaTag can not recover from this. PageImpression will run without ads. Reported error: %o', [v2CoreFile, event]),
        );
    }
}
