import { detectKnownIssues } from '../utils/issue-helper.js';
import { markSdg } from '../utils/performance.js';
import { MetaTag } from './essentials/init-metatag.js';
import { logInfo } from './essentials/logger.js';
import { AdslotRegistry } from './modules/adslot-registry.js';
import { ConsentCache } from './modules/consent-cache.js';
import { CoreFileLoader } from './modules/core-file-loader.js';
import { DependencyLoader } from './modules/dependency-loader.js';
import { InfotoolLoader } from './modules/infotool-loader.js';
import { SourcepointLoader } from './modules/sourcepoint-loader.js';
import { YieldloveLoader } from './modules/yieldlove-loader.js';

declare const window: Window & {
    SDG?: MetaTag;
};

markSdg(`script-is-loaded_mt`);

detectKnownIssues();

const SDG = window.SDG = new MetaTag(window.SDG?.cmd || []);
SDG.registerModule('CoreFileLoader', CoreFileLoader);
SDG.registerModule('SourcepointLoader', SourcepointLoader);
SDG.registerModule('ConsentCache', ConsentCache);
SDG.registerModule('YieldloveLoader', YieldloveLoader);
SDG.registerModule('DependencyLoader', DependencyLoader);
SDG.registerModule('AdslotRegistry', AdslotRegistry);
SDG.registerModule('InfotoolLoader', InfotoolLoader);

logInfo('MetaTag2 Loader - Version %s', [__METATAG_VERSION__]);
