import { HtmlElementEventDetailMap, SystemEvent, WindowEventDetailMap } from '@mbrtargeting/metatag-shared-types/metatag-core';
import { Browser, getBrowserDetails, isHTMLElement } from '@mbrtargeting/metatag-utils';
import { logDebug } from './logger.js';

/**
 * Creates a `CustomEvent`, special handling for IE browser.
 *
 * @param eventName name of the event
 * @param detail the payload of the event
 * @returns a CustomEvent instance
 */
const createEvent = <DETAIL>(eventName: string, detail: DETAIL): CustomEvent<DETAIL> => {
    // TODO: find out if babel is automatically handling IE event creation - maybe not required to do this manually?
    if (getBrowserDetails().browserName === Browser.InternetExplorer) {
        const event: CustomEvent<DETAIL> = document.createEvent('CustomEvent');
        event.initCustomEvent(eventName, true, true, detail);
        return event;
    }
    return new CustomEvent<DETAIL>(eventName, { detail });
};

/**
 * Triggers/dispatches an event to the `window` object.
 *
 * @param eventType a `SystemEvent` or `SystemSlotEvent` key
 * @param detail the payload of the event depending on the eventType
 */
export const triggerEvent = <K extends keyof WindowEventDetailMap>(eventType: K, detail: WindowEventDetailMap[K]) => {
    eventType !== SystemEvent.SDG_NEW_LOG_ENTRY && logDebug(`triggerEvent ${eventType} with details: %o`, [detail]);
    window.dispatchEvent(createEvent<WindowEventDetailMap[K]>(eventType, detail));
};

/**
 * Triggers/dispatches an event to an `HTMLElement`, if that element is present. If not, event will not be sent.
 *
 * @param target element to send the event to, element will be checked if eligible for triggering an event on
 * @param eventType a `SlotEvent` or `NodeEvent` key
 * @param detail the payload of the event depending on the eventType
 */
export const triggerHTMLElementEvent = <K extends keyof HtmlElementEventDetailMap>(target: Node | null, eventType: K, detail: HtmlElementEventDetailMap[K]) => {
    if (!isHTMLElement(target)) return;
    logDebug(`triggerHTMLElementEvent ${eventType}`);
    target.dispatchEvent(createEvent<HtmlElementEventDetailMap[K]>(eventType, detail));
};

const registerEventListener = (target: EventTarget, type: string, once: boolean, callback: Function) => {
    const listener = () => {
        if (once) target.removeEventListener(type, listener);
        callback();
    };
    target.addEventListener(type, listener);
};

/**
 * Internal memory to track already fired events.
 */
const triggeredEvents: Partial<Record<SystemEvent, boolean>> = {};

// listen for all `SystemEvent` events to keep `triggeredEvents` up to date
Object.values(SystemEvent).forEach(eventType => registerEventListener(window, eventType, true, () => {
    triggeredEvents[eventType] = true;
}));

export interface WaitOptions {
    once?: boolean;
    past?: boolean;
}

/**
 * Wait for a specific `SystemEvent` or run the callback directly when event already occurs.
 */
export const waitForEvent = (eventType: SystemEvent, callback: Function, options?: WaitOptions) => {
    const { past = true, once = true } = options || {};
    const wasTriggered: boolean = triggeredEvents[eventType] || false;
    // if the event happened before ("wasTriggered") and we are interested in "past" events, invoke the callback immediately
    if (wasTriggered && past) {
        callback();
    }
    if (
        // if the event happened before ("wasTriggered"), we were interested in "past" events and want to be notified more than once ("!once")
        (wasTriggered && past && !once) ||
        // if the event happened before ("wasTriggered"), but we were NOT interested in "past" events
        (wasTriggered && !past) ||
        // if the event did not happen yet
        (!wasTriggered)
    ) {
        registerEventListener(window, eventType, once, callback);
    }
};

export const waitForEventPromise = (eventType: SystemEvent) => new Promise<void>(resolve => waitForEvent(eventType, resolve, { once: true }));

// todo ggf. waitForSystemEvent und waitForSystemSlotEvent??
