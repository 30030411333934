import { AdSize, PublisherAPI, PublisherSlotAPI, VastUrlConfig, VastUrlResponse } from '@mbrtargeting/metatag-shared-types/metatag-core';
import { AdslotRegistry } from '../modules/adslot-registry.js';
import { ConsentCache } from '../modules/consent-cache.js';
import { Registry } from '../registry.js';
import { MetaTag } from './init-metatag.js';
import { logDeprecated, logError } from './logger.js';

declare const SDG: MetaTag;

export const createQueuePublisherAPI = (cmd: Function[]): PublisherAPI => {
    const getAdslotRegistryLazy = () => Registry.lookup<AdslotRegistry>('AdslotRegistry');
    const getConsentCacheLazy = () => Registry.lookup<ConsentCache>('ConsentCache');
    return {
        registerSlot: (slotName: string, container: string | HTMLElement): PublisherSlotAPI | null => {
            return getAdslotRegistryLazy().createAdslot(slotName, container);
        },
        unregisterSlot: (slotName: string, deleteAd?: boolean): boolean => {
            // TODO: what to do with unused "deleteAd" parameter?
            getAdslotRegistryLazy().deleteAdslot(slotName);
            return true;
        },
        unregisterAllSlots: (deleteAds?: boolean): boolean => {
            // TODO: what to do with unused "deleteAds" parameter?
            getAdslotRegistryLazy().deleteAllAdslots();
            return true;
        },
        registerModularSlot: (slotName: string, container: string | HTMLElement, width: number, height: number, adSizes: AdSize[], isMobile: boolean, useSubzone: string): PublisherSlotAPI | null => {
            return getAdslotRegistryLazy().createModularAdslot(slotName, container, width, height, adSizes, isMobile, useSubzone);
        },
        loadAllSlots: (reloadAds?: boolean): boolean => {
            cmd.push(() => {
                SDG.Publisher.loadAllSlots(reloadAds);
            });
            return true;
        },
        loadMultipleSlots: (slotNames: string[]): boolean => {
            cmd.push(() => {
                SDG.Publisher.loadMultipleSlots(slotNames);
            });
            return true;
        },
        loadSlot: (slotName: string): boolean => {
            cmd.push(() => {
                SDG.Publisher.loadSlot(slotName);
            });
            return true;
        },
        finalizeSlots: (): boolean => {
            cmd.push(() => {
                SDG.Publisher.finalizeSlots();
            });
            return true;
        },
        setZone: (zone: string): boolean => {
            cmd.push(() => {
                SDG.Publisher.setZone(zone);
            });
            return true;
        },
        addSubZone: (subZone: string): boolean => {
            cmd.push(() => {
                SDG.Publisher.addSubZone(subZone);
            });
            return true;
        },
        setPageType: (pageType: string): boolean => {
            cmd.push(() => {
                SDG.Publisher.setPageType(pageType);
            });
            return true;
        },
        addKeywords: (keywords: string[]): boolean => {
            cmd.push(() => {
                SDG.Publisher.addKeywords(keywords);
            });
            return true;
        },
        addKeyword: (keyword: string): boolean => {
            cmd.push(() => {
                SDG.Publisher.addKeyword(keyword);
            });
            return true;
        },
        removeKeywords: (keywords: string[]): boolean => {
            cmd.push(() => {
                SDG.Publisher.removeKeywords(keywords);
            });
            return true;
        },
        removeKeyword: (keyword: string): boolean => {
            cmd.push(() => {
                SDG.Publisher.removeKeyword(keyword);
            });
            return true;
        },
        addKeyValue: (key: string, value: string): boolean => {
            cmd.push(() => {
                SDG.Publisher.addKeyValue(key, value);
            });
            return true;
        },
        addKeyValues: (keyValues: Record<string, string[]>): boolean => {
            cmd.push(() => {
                SDG.Publisher.addKeyValues(keyValues);
            });
            return true;
        },
        removeKeyValue: (key: string): boolean => {
            cmd.push(() => {
                SDG.Publisher.removeKeyValue(key);
            });
            return true;
        },
        removeKeyValues: (keys: string[]): boolean => {
            cmd.push(() => {
                SDG.Publisher.removeKeyValues(keys);
            });
            return true;
        },
        blockFormats: (blockedFormats: string[]): boolean => {
            cmd.push(() => {
                SDG.Publisher.blockFormats(blockedFormats);
            });
            return true;
        },
        resetFormatBlocks: (): boolean => {
            cmd.push(() => {
                SDG.Publisher.resetFormatBlocks();
            });
            return true;
        },
        getCustomVendorConsent: (vendorName: string): boolean => {
            logDeprecated('PUBLISHER: getCustomVendorConsent("%s") is deprecated. Please use __tcfapi instead.', [vendorName]);
            return getConsentCacheLazy().getCustomVendorConsent(vendorName);
        },
        getIABVendorConsent: (vendorId: number, callback: (hasConsent: boolean) => void): void => {
            cmd.push(() => {
                SDG.Publisher.getIABVendorConsent(vendorId, callback);
            });
        },
        setAdServerConsent: (consent: boolean): void => {
            cmd.push(() => {
                SDG.Publisher.setAdServerConsent(consent);
            });
        },
        activateSecureProtocol: (): boolean => {
            cmd.push(() => {
                SDG.Publisher.activateSecureProtocol();
            });
            return true;
        },
        transitionAdvertisements: (): void => {
            cmd.push(() => {
                SDG.Publisher.transitionAdvertisements();
            });
        },
        generateVastUrls: (): VastUrlResponse => {
            logError('PUBLISHER: generateVastUrls() - empty response as core not loaded yet');
            return {};
        },
        getVastAdDataObject: (): VastUrlResponse => {
            logError('PUBLISHER: getVastAdDataObject() - empty response as core not loaded yet');
            return {};
        },
        generateVastUrlsPromise: (config: VastUrlConfig): Promise<VastUrlResponse> => {
            return new Promise((resolve, reject) => {
                cmd.push(() => {
                    SDG.Publisher.generateVastUrlsPromise(config).then(resolve).catch(reject);
                });
            });
        },
        getAdSlot: (slotName): PublisherSlotAPI | null => {
            return getAdslotRegistryLazy().getAdslot(slotName);
        }
    };
};
