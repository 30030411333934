import { SystemEvent } from '@mbrtargeting/metatag-shared-types/metatag-core';
import { isArray, loadScript } from '@mbrtargeting/metatag-utils';
import { inject, injectionTarget } from '../decorators/inject.js';
import { onSystemEvent } from '../decorators/on-system-event.js';
import { ConfigResolver } from '../essentials/config-resolver.js';
import { logDebug, logError } from '../essentials/logger.js';

@injectionTarget()
export class DependencyLoader {

    @inject() #configResolver!: ConfigResolver;

    /**
     * will load additional scripts
     */
    @onSystemEvent(SystemEvent.SDG_CONFIG_FILE_AVAILABLE)
    public loadDependencies(): void {
        const scripts: string[] | undefined = this.#configResolver.getConfig().dependencies?.scripts;
        if (!isArray(scripts)) return;

        scripts.map(src => loadScript({
            src,
            node: document.head,
            onload: () => logDebug('Script %o loaded successfully', [src]),
            onerror: (event) => logError('Script %o was not loaded. Check DevTools for further information.', [event]),
        }));
    }
}
