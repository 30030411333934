import { Registry } from '../registry.js';

const InjectSymbol = Symbol('Symbol.sdgInject');

/**
 * Describes a generic constructor of an class.
 */
type Constructor<T = object> = new (...args: any[]) => T;

interface InjectMetadata {
    fieldName: string;
    dependencyName: string;
}

/**
 * A decorator (mixin) to wrap a given class to support dependency injection.
 *
 * @returns a subclass of the given type
 */
export const injectionTarget = () => {
    return function decorator<Class extends Constructor>(target: Class, context: ClassDecoratorContext<Class>) {
        // TODO: this is an experimental approach -  not used yet
        // context.addInitializer((): void => {
        //     console.log('injectionTarget initializer', context.metadata[InjectSymbol]);
        // });
        // return class extends target {
        //     constructor(...args: any[]) {
        //         const injections = (context.metadata[InjectSymbol] ||= []) as InjectMetadata[];
        //         for (const { field, dependencyName } of injections) {
        //             const value = Registry.lookup(dependencyName);
        //             console.log('injectionTarget constructor', field, value);
        //             // TODO can not use "this" before super constructor call
        //             this[field] = value;
        //         }
        //         super(...args);
        //     }
        // }
    }
};

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

const normalize = (key: string) => {
    key = (key.startsWith('#')) ? key.slice(1) : key;
    key = capitalize(key);
    return key;
};

/**
 * A parameter decorator to mark constructor parameters as to be injected.
 *
 * @param key an optional lookup key of the resource
 * @returns an parameter decorator
 */
export const inject = (key?: string) => {
    return function decorator<Value>(_target: any, context: ClassFieldDecoratorContext<unknown, Value>) {
        const fieldName = context.name.toString();
        const dependencyName = normalize(key || context.name.toString());

        // TODO: this is an experimental approach -  not used yet
        const injections = (context.metadata[InjectSymbol] ||= []) as InjectMetadata[];
        injections.push({ fieldName, dependencyName });

        return (initialValue: Value): Value => {
            try {
                return Registry.lookup(dependencyName);
            } catch (e) {
                console.error(`error initializing field %s with dependency %s`, fieldName, dependencyName, e);
                return initialValue;
            }
        }
    }
};
