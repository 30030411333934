/**
 * Dependency Injection Registry
 */
export class Registry {

    // holding instances/values by key
    private static elements: Map<string, unknown> = new Map();

    // with private constructor prevent class from instantiating
    private constructor() { }

    /**
     * Registers a given object to a given key.
     * Each key can be registered only once.
     *
     * @param key a given key
     * @param value a given object to store
     */
    static register(key: string, value: unknown): void {
        if (Registry.elements.has(key)) throw new Error(`Registry: key ${key} already registered`);
        Registry.elements.set(key, value);
    }

    /**
     * Returns an object for an given key if registerd before.
     * Throws an error, if the key was not found.
     *
     * @param key a given key
     * @returns an object
     */
    static lookup<TYPE = unknown>(key: string): TYPE {
        const value = Registry.elements.get(key);
        if (!value) throw new Error(`Registry: key ${key} not found`);
        return value as TYPE;
    }

    static entries(): Iterable<[string, unknown]> {
        return this.elements.entries();
    }
}
